/* You can add global styles to this file, and also import other style files */
@import './assets/scss/_fonts';

html,
body {
  overflow-x: hidden;
  color: var(--theme-text-base);
  -webkit-font-smoothing: antialiased;
}

.main-content {
  margin-left: 28px;
  margin-right: 28px;
}

//-------- chart overrides -----------//

.apexcharts-tooltip-stacked .apexcharts-tooltip-series-group {
  padding-bottom: 0 !important;
}

.apexcharts-tooltip-stacked .apexcharts-tooltip-y-group {
  padding: 6px 0 2px !important;
}

.linechart-xaxis-label:first-of-type,
.linechart-xaxis-label:last-of-type {
  opacity: 1;
}

.linechart-xaxis-label {
  opacity: 0;
  transition: all 0.2s ease;

  &.active {
    fill: #000;
    font-weight: bold;
    opacity: 1;
  }
}

// Prevent page shift
body.cdk-global-scrollblock {
  position: static !important;
  width: auto !important;
  overflow: hidden !important;
}