@tailwind base;
@tailwind components;
@tailwind utilities;
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-family: Inter;
@primary-color: #293241;
@text-color: #5D5C5C;
@heading-color: #5D5C5C;

:root {
  --theme-primary: #293241;
  --theme-blue-200: #293241;
  --theme-blue-100: #98C1D9;
  --theme-blue-50: #E0FBFC;
  --theme-secondary: #D86F6F;
  --theme-grey-100: #EFEFEF;
  --theme-grey-50: #F7F7F8;
  --theme-error: #EE6C4D;
  --theme-success: #3A9D23;
  --theme-warning: #EFAB59;
  --theme-info: #D2DBEE;
  --theme-muted: #00000073;
  --theme-text-base: #5D5C5C;
  --theme-base-bg: #f0f2f5;
  --theme-dark-border: rgb(110, 116, 126, 0.35);
  --cropper-outline-color: rgba(0, 0, 0, 0.7);
  --shadow-4-4-10: 4px 4px 10px rgba(0, 0, 0, 0.25);
  --sidebar-width: 200px;
}

.text-xxs {
  font-size: 10px;
}

.ant-layout-header {
  line-height: normal;
}

.ant-form-item-label>label .ant-form-item-tooltip {
  position: relative;
  top: -3px;
}

.ant-select-selector {
  border-radius: 2px !important;
}

.ant-form-item-label>label {
  color: inherit;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--theme-skyblue);
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--theme-primary);
  color: var(--theme-grey-50);
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: var(--theme-grey-50);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--theme-secondary);
}

.ant-timeline-item-last>.ant-timeline-item-content {
  min-height: auto;
}

.ant-timeline-item.ant-timeline-item-last {
  padding-bottom: 0;
}

.escalation-timeline .ant-timeline-item-head-custom {
  background-color: var(--theme-grey-100);
}

.dashboard-tabs .ant-tabs-nav {
  background-color: white;
  padding-left: 30px;
  padding-top: 8px;
  border-bottom: 1px solid #e8e8e8;
}


.card-loader .ant-skeleton-content {
  vertical-align: middle;
}

.card-loader .ant-skeleton-content .ant-skeleton-title {
  margin: 0;
}

svg {
  vertical-align: baseline;
}

img.ant-image-preview-img {
  display: inline-block;
  user-select: none;
  pointer-events: none;
}

img.ngx-ic-source-image {
  margin: auto;
}

// fix image preview

.ant-alert-success {
  background-color: var(--theme-yellow);
  border-color: #dcb754;
}

nz-alert {
  .ant-alert-success {
    border-radius: 20px;
  }

  &.square {
    .ant-alert-success {
      border-radius: 0;
    }
  }
}

.ant-table-thead>tr>th {
  font-weight: bolder;
  color: black;
}

.ant-tag.small {
  margin-right: 6px;
  padding: 0px 4px;
  font-size: 10px;
  line-height: 17px;
}

// .ant-message-notice-content:has(.ant-message-success) {
//   background-color: var(--theme-green);
//   border-color: var(--theme-green);
//   border-radius: 20px;
//   color: white;

//   .anticon {
//     color: white;
//   }
// }

// .ant-message-notice-content:has(.ant-message-error) {
//   background-color: white;
//   border-color: white;
//   border-radius: 20px;

//   .anticon {
//     color: var(--theme-primary);
//   }
// }

#main-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-bg-grey);
}