@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}